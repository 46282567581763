.App {
  text-align: center;
}
.main{
  height: 100%;
}
.mainText{
  padding: 50px;
  letter-spacing: 0.3px;
}
.App-logo {
  height: 50vmin;
  pointer-events: none;
  padding: 5px;
  border: 5px solid black;
  position: fixed;
  right: 15%;
  top: 20%;
}
.imageContainer{
  padding-top: 10%;
}
.h2title{
  padding-bottom: 3px;
  border-bottom-style: solid;
  width:fit-content;
}
.right {
background-color: darkslateblue;
height: unset;
}
.h6subtitle{
  color:darkslateblue
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
span{
  text-decoration:underline;
  font-weight: bold;
}